var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-screen"},[_c('div',{staticClass:"map"},[(_vm.coords && _vm.zoom)?_c('yandex-map',{attrs:{"coords":_vm.coords,"zoom":_vm.zoom,"controls":['zoomControl']},on:{"map-was-initialized":_vm.mapInitHandler}},_vm._l((_vm.mapObjects),function(object){return _c('ymap-marker',{key:`${object.id}`,attrs:{"marker-type":"Placemark","marker-id":`${object.id}`,"coords":object.location,"icon":object.type.image ? 
                {
                  layout: 'default#image',
                  imageHref: object.type.image, // for tests: http://panels-dev.dnadev.ru/img/obj-ico-1.svg
                  imageSize: [30, 30], 
                  imageOffset: [-15, -15],
                }
                : _vm.selectedObject 
                    ? object.id == _vm.selectedObject.id 
                      ? {color:'blueDot'}
                      : {color:'grey'}
                    : {color:'blue'},"options":_vm.selectedObject && object.id == _vm.selectedObject.id
                ? {zIndex: 1000} : {}},on:{"click":function($event){return _vm.selectObject(object)}}})}),1):_vm._e()],1),_c('div',{staticClass:"tags"},[_c('swiper',{attrs:{"options":{slidesPerView: 'auto', touchRatio: 2}}},[_c('swiper-slide',[_c('tag',{attrs:{"title":"Все","active":_vm.$route.params.type == 0},nativeOn:{"click":function($event){return _vm.changeType(0)}}})],1),_vm._l((_vm.objectTypes),function(tag,index){return _c('swiper-slide',{key:index},[_c('tag',{attrs:{"title":tag.name,"active":tag.id==_vm.$route.params.type},nativeOn:{"click":function($event){return _vm.changeType(tag.id)}}})],1)})],2)],1),_c('div',{staticClass:"objects"},[_c('swiper',{staticClass:"swiper-container swiper-container_sights",attrs:{"options":{slidesPerView: 'auto', touchRatio: 2}}},_vm._l((_vm.objects),function(object,index){return _c('swiper-slide',{key:index},[_c('object-card',{attrs:{"img":object.images[Object.keys(object.images)[0]].replace('extra','small'),"title":object.title,"category":object.type.name},nativeOn:{"click":function($event){return _vm.selectObject(object)}}})],1)}),1)],1),_c('pop-up')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }