<template>
  <div class="map-screen">
    <!-- <img src="@/assets/img/iamicon.svg" /> -->
    <div class="map">
      <yandex-map
        v-if="coords && zoom"
        :coords="coords"
        :zoom="zoom"
        :controls="['zoomControl']"
        @map-was-initialized="mapInitHandler"
      >
        <ymap-marker
          v-for="object in mapObjects"
          :key="`${object.id}`"
          marker-type="Placemark"
          :marker-id="`${object.id}`"
          :coords="object.location"
          :icon="object.type.image ? 
                  {
                    layout: 'default#image',
                    imageHref: object.type.image, // for tests: http://panels-dev.dnadev.ru/img/obj-ico-1.svg
                    imageSize: [30, 30], 
                    imageOffset: [-15, -15],
                  }
                  : selectedObject 
                      ? object.id == selectedObject.id 
                        ? {color:'blueDot'}
                        : {color:'grey'}
                      : {color:'blue'}
          "
          :options="
                selectedObject && object.id == selectedObject.id
                  ? {zIndex: 1000} : {}
              "
          @click="selectObject(object)"
        />
      </yandex-map>
    </div>

    <div class="tags">
      <swiper :options="{slidesPerView: 'auto', touchRatio: 2}">
        <swiper-slide>
          <tag title="Все" :active="$route.params.type == 0" @click.native="changeType(0)" />
        </swiper-slide>
        <swiper-slide v-for="(tag, index) in objectTypes" :key="index">
          <tag
            :title="tag.name"
            :active="tag.id==$route.params.type"
            @click.native="changeType(tag.id)"
          />
        </swiper-slide>
      </swiper>
    </div>

    <div class="objects">
      <swiper
        class="swiper-container swiper-container_sights"
        :options="{slidesPerView: 'auto', touchRatio: 2}"
      >
        <swiper-slide v-for="(object, index) in objects" :key="index">
          <object-card
            :img="object.images[Object.keys(object.images)[0]].replace('extra','small')"
            :title="object.title"
            :category="object.type.name"
            @click.native="selectObject(object)"
          />
        </swiper-slide>
      </swiper>
    </div>

    <pop-up />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Tag from "@/components/Parts/Tag";
import ObjectCard from "@/components/Parts/ObjectCard";
import PopUp from "@/components/MapPopUp";

export default {
  components: { Tag, ObjectCard, PopUp },
  data() {
    return {
      map: null
      // zoom: 13
    };
  },
  computed: {
    ...mapGetters([
      "coord",
      "zoom",
      "objectTypes",
      "objects",
      "selectedObject"
    ]),
    coords() {
      if (this.selectedObject) return this.selectedObject.location;
      return this.coord;
    },
    mapObjects() {
      if (this.selectedObject) return [this.selectedObject];
      return this.objects;
    }
  },
  watch: {
    mapObjects() {
      if (this.map) {
        this.map.geoObjects.removeAll();
        const myPlacemark = new ymaps.Placemark(
          this.coord,
          {},
          {
            iconLayout: "default#image",
            iconImageHref:
              "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTkiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA1OSA0OCI+CjxkZWZzPgo8Y2lyY2xlIGlkPSJiIiBjeD0iMjQiIGN5PSIyNCIgcj0iMjQiPjwvY2lyY2xlPgo8ZmlsdGVyIGlkPSJhIiB3aWR0aD0iMTM3LjUlIiBoZWlnaHQ9IjEzNy41JSIgeD0iLTE4LjglIiB5PSItMTguOCUiIGZpbHRlclVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+CjxmZU9mZnNldCBpbj0iU291cmNlQWxwaGEiIHJlc3VsdD0ic2hhZG93T2Zmc2V0T3V0ZXIxIj48L2ZlT2Zmc2V0Pgo8ZmVHYXVzc2lhbkJsdXIgaW49InNoYWRvd09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dCbHVyT3V0ZXIxIiBzdGREZXZpYXRpb249IjMiPjwvZmVHYXVzc2lhbkJsdXI+CjxmZUNvbG9yTWF0cml4IGluPSJzaGFkb3dCbHVyT3V0ZXIxIiB2YWx1ZXM9IjAgMCAwIDAgMC4wNCAwIDAgMCAwIDAuMDkzMzMzMzMzMyAwIDAgMCAwIDAuMiAwIDAgMCAwLjI3MzA4MjM4NiAwIj48L2ZlQ29sb3JNYXRyaXg+CjwvZmlsdGVyPgo8L2RlZnM+CjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CjxwYXRoIGZpbGw9IiNENzUwMEMiIGQ9Ik00MC4yMzUgMzkuNTNMNTguNTg4IDI0IDQwLjIzNSA4LjQ3eiI+PC9wYXRoPgo8dXNlIGZpbGw9IiMwMDAiIGZpbHRlcj0idXJsKCNhKSIgeGxpbms6aHJlZj0iI2IiPjwvdXNlPgo8Y2lyY2xlIGN4PSIyNCIgY3k9IjI0IiByPSIyNCIgZmlsbD0iI0ZGNTYwRCI+PC9jaXJjbGU+CjxwYXRoIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTIzLjYzMiAyNi44MmwtNC41MDQgNi42MTRoLTMuNTk5bDQuODY0LTcuMDQ4Yy0yLjM4Mi0uNjctMy45OTUtMi42MDUtMy45OTUtNS4xNzQgMC0zLjM3NSAyLjQ3LTUuNjgzIDYuMjI5LTUuNjgzaDcuMDM1djE3LjkwNWgtMy4xNzZ2LTYuNjEzaC0yLjg1NHptMi44NTQtMi41MDZ2LTYuMTc5aC0zLjMxM2MtMi4yMDkgMC0zLjUgMS4xNDItMy41IDMuMDkgMCAxLjk2IDEuMjkxIDMuMDkgMy41IDMuMDloMy4zMTN6Ij48L3BhdGg+CjwvZz4KPC9zdmc+", // for tests: http://panels-dev.dnadev.ru/img/obj-ico-1.svg
            iconImageSize: [59, 48],
            iconImageOffset: [-59, -24]
          }
        );
        this.map.geoObjects.add(myPlacemark);
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    mapInitHandler(obj) {
      this.map = obj;
    },

    fetchData() {
      this.$store.dispatch("fetchObjectTypes");
      this.$store.dispatch("fetchObjects", {
        type: this.$route.params.type,
        page: 1
      });
    },

    changeType(type) {
      if (this.$route.params.type !== type) {
        this.$router.replace({
          path: `/map/${type}`,
          query: this.$route.query
        });
        this.fetchData();
      }
    },

    selectObject(object) {
      this.$store.dispatch("setSelectedObject", object);
      this.$store.dispatch("fetchNearObjects", object.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.map-screen {
  .map,
  .ymap-container {
    height: 720px;
  }

  .objects {
    .swiper-slide {
      width: 300px;

      margin-right: 20px;

      &:first-child {
        margin-left: 32px;
      }

      &:last-child {
        margin-right: 32px;
      }
    }
  }
}
</style>